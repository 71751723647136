import { MaintainsEnumeration } from '@/constants/entertainment-area'
import { RAGameList, RaGameTypeEnum } from '@/constants/game'

export enum HourseLampEnum {
  main = 'announcement',
  article = 'announcementArticle',
}

/** 游戏馆类型展示 */
export enum HallGameGroupTypeEnum {
  yes = 'yes',
  no = 'no',
}

/**
 * 判断当前是否原创游戏
 */
export const getIsRa = (code: string | RaGameTypeEnum = '') => {
  return RAGameList.includes(code)
}

/**
 * 娱乐区项目类型
 */
export enum ProjectCodeEnum {
  /** 币对大作战 */
  tradePair = 'trade_pair',
  /** 哈希 */
  hash = 'hash',
  /** 哈希大作战 */
  hashBattle = 'hash_fight',
}

export enum StrategyEnum {
  // 大小
  bigOrSmall = 'big_or_small',
  // 数字
  number = 'number',
  // 单双
  parity = 'parity',
}

export enum HallGameCode {
  original = 'nc_original',
}

export enum GameTypeEnum {
  menu = 'type_hot',
  hall = 'hall',
}

/**
 * 判断维护中或者区域限制禁用方法
 * @param data 游戏信息
 */
export function getGameDisableStatus(data) {
  let disableInfo = {
    /** 维护状态 */
    maintenanceStatus: false,
    /** 地区状态 */
    isCurrentRegionStatus: false,
    /** 是否点击 */
    isClick: data.maintenanceStatusCd === MaintainsEnumeration.Maintenance || !data.isCurrentRegion,
  }

  if (data.maintenanceStatusCd === MaintainsEnumeration.Maintenance) return { ...disableInfo, maintenanceStatus: true }

  if (!data.isCurrentRegion) return { ...disableInfo, isCurrentRegionStatus: true }

  return disableInfo
}
